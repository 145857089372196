$z1-bg-color: #ffffff;
$z1-shadow-color: #d4d5d9;
$main-bg-color: #f5f6fb;
$sub-bg-color: #dde3ec;

$text-color: #2f3f56;
$muted-color: #8592A3;

$button-action-bg-color: #657ef8;
$button-action-text-color: #ffffff;
$button-text-color: #657ef8;
$button-bg-color: #d6dcfa;

$green-color: #aed848;

@import 'desktopStyles.scss';

@media only screen and (max-width: 768px) {
  @import 'mobileStyles.scss';
}
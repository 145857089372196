$main-font: 'Roboto', sans-serif;
$site-nav-bar-height: 60px;
$main-font-size: 16px;
$sub-font-size: 15px;
$mini-font-size: 14px;
$ingredient-img-size: 50px;
$border-radius: 16px;

body {
  font: 100% $main-font;
  font-size: $main-font-size;
  background-color: $main-bg-color;
  color: $text-color;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}

.site-nav-bar {
  width: 100%;
  height: $site-nav-bar-height;
  box-shadow: 0 2px 4px $z1-shadow-color;
  position: fixed;
  z-index: 1;
  background-color: $z1-bg-color;

  .desktop-nav-bar-links {
    text-decoration: none;
    color: $muted-color;
    padding: 1em;
    cursor: pointer;
    border: 3px solid $z1-bg-color;

    &:hover {
      color: $button-text-color;
      background-color: $button-bg-color;
      border-radius: $border-radius;
    }
  }

  .nav-bar-link-selected {
    color: $button-text-color !important;
  }

  .nav-bar-link-default {
    color: $muted-color;
  }

  .mobile-logo-link-container {
    display: inline;
  }

  .logo {
    position: relative;
    top: 10px;
  }

  .logo-link {
    padding: 0.9em;
    cursor: pointer;
    border-radius: 0;
    border: 3px solid $button-bg-color;
  }

  .inner-nav-bar {
    padding: 5px;
  }

  .inventory-icon {
    float: right;
  }

  .side-menu {
    display: none;
    color: $muted-color;
    padding: 1em;
  }
}

.sub-page-body {
  padding-top: $site-nav-bar-height;
}

.cooking-page-display {
  display: grid;
  grid-template-areas:
  "cards sidebar";
  grid-template-columns: auto 375px;
  min-height: 90vh;
}

.sidebar-display {
  grid-area: sidebar;
  width: 375px;
  padding-bottom: 10px;
  margin-left: auto;
  margin-right: 8px;
  margin-top: 15px;

  .add-recipe-button-div {
    border-radius: $border-radius;
    background-color: $z1-bg-color;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
    margin-bottom: 10px;
  }

  .grocery-div {
    border-radius: $border-radius;
    background-color: $z1-bg-color;

    .sidebar-card-display {
      margin-bottom: 10px;
    }

    .header-text {
      text-align: center;
      padding: 1em;
      border-bottom: 1px solid $muted-color;
    }

    .header-text-container {
      display: flex;
      padding: 1em;
      border-bottom: 1px solid $muted-color;

      .title {
        text-align: center;
        width: 100%;
      }

      .settings-container {
        float: right;
        margin-right: 10px;
      }
    }

    .top-bar {
      color: $text-color;
      border-top-right-radius: 16px;
      border-top-left-radius: 16px;
    }

    .grocery-list-top-bar {
      margin-left: 15%;
      margin-right: 15%;
    }

    .grocery-list-cards-div {
      flex-wrap: wrap;
      min-height: 20px;
      padding-bottom: 15px;
      padding-left: 5px;
      padding-right: 5px;
      margin-top: 15px;
      margin-left: 15%;
      margin-right: 15%;
    }

    .cooking-pot-ingredient-container {
      padding: 4px;

      .cooking-pot-ingredient-img {
        width: $ingredient-img-size;
        height: $ingredient-img-size;
        border-radius: $border-radius;
      }
    }
  }

  .modal-button {
    margin: 0 0.5em;
  }

  .dropdown-display-none {
    display: none;
  }
}

.recipe-card-display {
  grid-area: cards;
  flex-wrap: wrap;

  .recipe-card-grid {
    $recipe-img-size: 125px;
    background-color: $z1-bg-color;

    display: grid;
    width: 350px;
    height: 265px;
    border-radius: 16px;
    margin-right: 8px;
    grid-template-areas:
    "top-bar top-bar"
    "main-img recipe-progress"
    "ingredients ingredients";

    .recipe-top-bar {
      grid-area: top-bar;
      border-bottom: 1px solid $z1-shadow-color;
      margin-left: 0.5em;
      margin-right: 0.5em;
      padding: 1em 1em 0.25em;

      .recipe-name {
        color: $text-color;
        width: 80%;
        text-align: center;
        margin-top: 0.2em;
        margin-left: 1em;
      }

      .power-button-margin {
        margin-right: 3px;
      }

      .power-button {
        margin-right: 10px;
      }
    }

    .recipe-progress {
      grid-area: recipe-progress;
      font-size: $mini-font-size;
      color: $muted-color;

      .recipe-progress-fields {
        padding-right: 0.5em;
        padding-top: 1em;
        font-size: $mini-font-size;
        min-width: 70px;
      }

      .progress-field {
        margin-right: 1.5em;
      }

      .prof-field {
        height: 1.25em;
      }

      .recipe-data-field {
        font-size: $mini-font-size;
      }

      .cook-button-div {
        width: 90%;
        margin-bottom: 1em;
        margin-top: 10px;
      }

      .modal-button {
        font-size: $mini-font-size;
      }

      .checkmark-overlay {
        padding-top: 0.5em;
      }
    }

    .recipe-img {
      grid-area: main-img;
      width: $recipe-img-size;
      max-height: $recipe-img-size;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 35px;
      margin: 10px 0;
    }

    .recipe-ingredient-div {
      grid-area: ingredients;
      border-top: 1px solid $z1-shadow-color;
      margin: 0 0.5em 5px;
      padding-top: 0.5em;
    }

    .message-modal .edit-popup .top-bar {
      color: $text-color;
    }
  }
}

.modal {
  position: fixed;
  z-index: 1;
  padding: 1em;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
  box-sizing: border-box;

  .modal-card {
    border-radius: 16px;
    background-color: $z1-bg-color;
    overflow-y: scroll;
    width: 100%;
    max-width: 1000px;
  }

  .modal-content {
    padding: 1em 0;
    max-width: 700px;
  }

  .modal-nav-bar {
    display: flex;
    justify-content: space-between;
    border-bottom: 3px solid $z1-shadow-color;
  }

  .modal-nav-bar-icon {
    width: 50px;
    height: 50px;
  }

  .modal-nav-bar-tab-default {
    opacity: 0.5;
  }

  .modal-nav-bar-tab-selected {
    background-color: $button-bg-color;
    border-radius: 8px;
    color: $button-text-color;
    opacity: 0.8;
  }

  .modal-nav-bar-tab-default:hover,
  .modal-nav-bar-tab-selected:hover {
    background-color: $button-bg-color;
    border-radius: 8px;
  }

  .modal-legend-icon {
    color: $green-color;
    padding: 10px;  
    .modal-legend-meaning {
      display: inline-block;
      vertical-align: super;
    }
  }

  .modal-content {
    margin: auto;
    padding: 20px;
    background-color: $z1-bg-color;
  }

  .save-cancel-buttons {
    display: flex;

    .modal-button {
      margin-right: 0.5em;
    }
  }
}

.message-modal-body {
  padding: 2em 2em 3em;
}

.card {
  margin: 15px;
}

.card-icon {
  width: 85%;
  font-size: 10px;
}

.recipe-label-field {
  cursor: pointer;
  padding-top: 0.5em;
  display: block;
}

.ingredient-card {
  width: 60px;
  height: 86px;
  border-radius: 5px;
  margin: 6px 6px 1em;

  .card-img {
    background-repeat: no-repeat;
    border-radius: 10px;
  }

  .card-text-field {
    margin-top: 0.5em;
    width: 46px !important;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
}

.cards {
  flex-wrap: wrap;
  padding-top: 5px;
}

.flex-center {
  display: flex;
  justify-content: center;
}

.vertical-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.cookbook-card {
  position: relative;
  min-height: 130px;
  width: 100px;
  border-radius: 10px;
  margin: 6px;
  font-size: $mini-font-size;

  background-size: 100px;
  background-repeat: no-repeat;

  cursor: pointer;
  padding: 0.5em;

  border: 2px solid $z1-shadow-color;

  transition: all .2s ease-in-out;

  .card-img {
    border-radius: 10px;
  }

  .checkmark-overlay {
    position: absolute;
  }
}

.modal-button {
  font-size: $main-font-size;
  background-color: $button-bg-color;
  color: $button-action-bg-color;
  border: none;
  border-radius: 16px;
  cursor: pointer;
  padding: 1em;

  transition: all .1s ease-in-out;

  &:hover {
    background-color: $button-action-bg-color;
    color: $button-action-text-color;
  }
}

.small-button {
  background-color: $button-bg-color;
  color: $button-text-color;
  width: $ingredient-img-size;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: $mini-font-size;
  padding: 0.25em;

  transition: all .1s ease-in-out;

  &:hover {
    background-color: $button-text-color;
    color: #fff;
  }
}

.message-modal {
  position: fixed;
  z-index: 2;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);

  .popup {
    background-color: $z1-bg-color;
    border-radius: 16px;
    padding: 1em;
  }

  .delete-popup {
    padding: 1em 2em;

    .top-bar {
      float: right;
      padding-top: 15px;
    }
  }

  .edit-popup {
    .ingredients-border {
      border-right: 1px solid $z1-shadow-color;
    }

    .top-bar {
      border-bottom: 1px solid $z1-shadow-color;
      padding: 15px;
    }

    .input-label {
      text-align: center;
      inline-size: 10em;
    }

    .text-label {
      padding-left: 0.3em;
    }

    .input-field-div {
      margin: 15px 32px 0;
      display: flex;
      flex-direction: column;

      .inner-field-div {
        margin-bottom: 0.4em;
      }
    }

    .modal-button {
      width: 100%;
      margin-top: 5px;
    }

  }

  .cooking-popup {
    display: flex;
    flex-direction: column;
    min-width: 650px;

    .top-bar {
      border-bottom: 1px solid $z1-shadow-color;
      padding: 15px;

      .cook-recipe-name-text {
        font-size: $mini-font-size;
      }
    }

    .cooking-field-title {
      font-size: 14px;
      text-align: center;
      margin-top: 2em;
    }

    .cooking-parent {
      display: flex;
      flex-direction: column;
    }

    .cooking-body {
      margin: 0 2em 1em 4em;

      .cooking-field-div {
        .text-field {
          width: 3em;
          height: 2em;
          margin-top: 0.4em;
        }
      }

      .info-icon {
        height: 20px;
        width: 20px;
        opacity: 0.7;
        color: $button-text-color;

        &:hover {
          opacity: 1;
        }
      }
    }

    .progress-display-div {
      display: flex;
      flex-direction: column;
      text-align: center;
      font-size: $mini-font-size;
      margin-bottom: 2em;
      color: $muted-color;
    }

    .ingredients-div {
      display: flex;
      justify-content: center;

      .ingredients-border {
        border-right: 1px solid $z1-shadow-color;
      }

      .ingredients-wrapper {
        text-align: center;
        padding: 1em 2em;
        border-top: 1px solid $z1-shadow-color;

        .ingredient-cards-title {
          font-size: $mini-font-size;
        }
      }
    }

    .ingredient-warning-text {
      background-color: rgba(0,0,0,0.4);
      color : #ff4f4f;
    }

    .modal-button {
      padding: 1em 2em;
    }
  }

  .edit-mini-ingredient-popup {
    color: $text-color;
    display: flex;
    flex-direction: column;
    max-height: 310px;
    width: 275px;

    .edit-mini-ingredient-top-bar {
      border-bottom: 1px solid $z1-shadow-color;
      padding: 15px;
    }

    .input-field-div {
      margin: 15px 32px 0;
      display: flex;
      flex-direction: column;
      text-align: center;
    }

    .ingredient-img {
      width: 50px;
      border-radius: $border-radius;
      margin-top: 1em;
    }
  }

  .settings-popup {
    .top-bar {
      border-bottom: 1px solid $z1-shadow-color;
      padding: 15px;
    }
  }

  .close {
    color: $muted-color;
    width: 35px;
    height: 35px;
    margin-top: -10px;
    float: right;
    cursor: pointer;

    &:hover {
      color: $muted-color;
      background-color: white;
    }
  }

}

.mini-ingredient-container {
  position: relative;
  color: $z1-bg-color;
  margin-left: 2px;
  padding: 4px;

  .mini-ingredient-card {
    width: $ingredient-img-size;
    height: $ingredient-img-size;
    border-radius: 16px;
    cursor: pointer;
  }

  .ingredient-count-overlay {
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    margin-bottom: 8px;
    width: $ingredient-img-size;
    position: absolute;
    text-align: center;
    font-size: $mini-font-size;
    background-color: rgba(0,0,0,0.2);
    bottom: 0;
    cursor: pointer;
  }
}

.text-field {
  text-align: center;
  cursor: pointer;
  background-color: $main-bg-color;
  padding: 0 5px;
  border-radius: 4px;
  border: 1px solid $z1-shadow-color;
  width: 3em;
  height: 2em;
}

.kofi-icon-div {
  position: fixed;
  z-index: 99;
  bottom: 1em;
  right: 1em;
  display: flex;
  cursor: pointer;
  border: 5px solid white;
  border-radius: 12px;
  background-color: $button-bg-color;
  color: $button-text-color;
  box-shadow: 0 2px 4px $z1-shadow-color;
  text-align: center;
  padding-right: 0.5em;

  transition: all .2s ease-in-out;

  .kofi-icon {
    width: 70px;
    height: 70px;
  }

  &:hover {
    transform: scale(1.1);
  }
}

.donate-popup {
  display: flex;
  flex-direction: column;
  max-height: 270px;
  border: 10px solid $button-bg-color;

  .top-bar {
    border-bottom: 1px solid $z1-shadow-color;
    padding: 15px;
  }

  .sub-title {
    font-size: $sub-font-size;
    margin-top: 1em;
    text-align: center;
    padding: 0.5em;
  }
}

.donate-icon-div {
  margin: 1em;

  .donate-icon {
    padding-bottom: 1em;
    width: 50px;
    height: 50px;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  .patreon-icon {
    width: 50px;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }
}

.disabled-button {
  opacity: 0.6;
  cursor: auto;

  &:hover {
    background-color: $button-bg-color;
    color: $button-text-color;
  }
}

.toggle-text {
  color: $muted-color;
  font-size: $mini-font-size;
}

.toggle {
  color: #b6c2fc;
}

.disabled-card {
  opacity: 0.6;
}

.large-font {
  font-size: 20px;
}

.xlarge-font {
  font-size: 50px;
}

.placeholder-div {
  text-align: center;
  height: 90vh;
}

.help-container {
  height: 90vh;
  width: 100%;

  .help-text-container {
    text-align: center;
    margin: 0.5em;
  }

  .tutorial-character {
    height: 200px;
    width: 200px;
  }

  .modal-button {
    margin: 0.5em;
  };
}

.svg-icon {
  color: $muted-color;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: $button-text-color;
    background-color: $button-bg-color;
  }
}

.text-align-center {
  text-align: center;
}

.column-flex {
  flex-direction: column;
}

.padding {
  padding: 1em;
}

.reduced-font-size {
  font-size: $mini-font-size;
}

.padding-right {
  padding-right: 0.5em;
}

.padding-left {
  padding-left: 0.5em;
}

.padding-top {
  margin-top: 0.5em;
}

.paimon {
  width: 200px;
  height: 200px;
}

.default-color {
  color: $muted-color;
}

.checkmark-overlay {
  color: $green-color;
}

.home-page-container {
  min-height: calc(100vh - 100px);

  .welcome-banner {
    padding-bottom: 20px;
  }

  .message-body {
    padding-bottom: 60px;
  }

  .image-container {
    display: flex;
    justify-content: center;
  }

  .sparkling-icon {
    width: 30px;
    height: 30px;
  }
}

.footer {
  width: 100%;
  height: 60px;
  background-color: $sub-bg-color;
  align-items: center;

  .footer-icons {
    width: 35px;
    height: 35px;
    cursor: pointer;
  }

  .discord-icon {
    width: 35px;
    height: 30px;
    cursor: pointer;
  }
}

.home-page-img {
  width: 200px;
  height: 200px;
  cursor: pointer;
}

.cookie-png {
  width: 35px;
  height: 35px;
}

.offside-font {
  font-family: 'Offside', sans-serif;
}

.top-bar {
  border-bottom: 1px solid $z1-shadow-color;
}

.font-bold {
  font-weight: bold;
}

.close {
  color: $muted-color;
  float: right;
  width: 35px !important;
  height: 35px !important;
  border-radius: 5px;
  cursor: pointer;
  margin-top: -10px;

  &:hover {
    color: $button-text-color;
    background-color: $button-bg-color;
  }
}

.cookie-popup-close {
  color: $muted-color;
  width: 35px !important;
  height: 35px !important;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    color: $button-text-color;
    background-color: $button-bg-color;
  }
}

.border-bottom {
  border-bottom: 1px solid $muted-color;
}

.donate-bottom-padding {
  padding-bottom: 70px;
}

::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-thumb {
  background-color: $sub-bg-color;
  border-radius: 9px;
  border: 4px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

